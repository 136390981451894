import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { TrashIcon, PhotoIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

import CommonButtonDefault from '../buttons/default';
import { useAuth } from '../../../hooks/use-auth';
import { SimpleObject } from '../../../entities/simple-object';
import AlertPrimary from '../alerts/alert-primary';
import { strings } from '../../../localization/strings';
import { upload } from '../../../services/api/photos';
import CommonAnimatedLoader from '../animated/loader';

interface UploadedDocument {
  file: File;
  preview: string;
  albumId?: number;
}

interface ComponentProps {
  albumId?: number;
}

function PhotosDropzone({ albumId }: ComponentProps) {
  const { open } = useDropzone();
  const [photos, setPhotos] = useState<UploadedDocument[]>([]);
  // const { companyDetails, setCompany } = useCompany();
  const { token } = useAuth();
  const [errors, setErrors] = useState<SimpleObject>();
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  // Callback for handling dropped files
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setPhotos([
        ...photos,
        ...acceptedFiles.map((f) => {
          return { file: f, preview: URL.createObjectURL(f) };
        }),
      ]);
    },
    [photos],
  );

  const onRemoveFile = (file: File) => {
    const newFiles = [...photos];

    newFiles.splice(
      newFiles.findIndex((f) => f.file === file),
      1,
    );

    setPhotos(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    useFsAccessApi: false,
  });

  const fileUpload = async (file: File): Promise<{ status: boolean; id: number; message: string }> => {
    try {
      setIsLoading(true);

      const uploaded = await upload(token, file, albumId);

      setIsLoading(false);

      return {
        status: true,
        id: uploaded.id,
        message: '',
      };
    } catch (e) {
      setIsLoading(false);

      return {
        status: false,
        id: 0,
        message: (e as Error).message,
      };
    }
  };

  async function onSubmitFiles() {
    if (photos.length > 0) {
      const results = await Promise.all(
        photos.map(async (f) => {
          return fileUpload(f.file);
        }),
      );

      const hasErrors = results.reduce((e, file) => e || !file.status, false);
      const filesWithError = results.filter((r) => !r.status);

      if (filesWithError) {
        setErrors(filesWithError);
      }

      if (!hasErrors) {
        const newFiles = [...photos];

        const ids: number[] = [];

        results.forEach((f) => {
          ids.push(f.id);
        });

        const queryString = `&ids=${ids.join('&ids=')}`;

        photos.forEach((f) => {
          newFiles.splice(newFiles.indexOf(f), 1);
        });
        setPhotos(newFiles);

        setSuccess(true);

        if (albumId) {
          navigate(`/photos/local/edit?afterUpload=1&albumId=${albumId}${queryString}`);
        } else {
          navigate(`/photos/local/edit?afterUpload=1${queryString}`);
        }
      }
    }
  }

  return (
    <div>
      {success && (
        <div className="mb-3">
          <AlertPrimary type="success" size="xs" text="Failai sėkmingai įkelti" />
        </div>
      )}
      {isLoading && <CommonAnimatedLoader />}
      <section className="container">
        <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2 -mx-4 sm:mx-0">
            <div className="bg-white p-4 shadow sm:rounded-lg ring-1 ring-gray-900/5">
              <div
                {...getRootProps({
                  className:
                    'dropzone justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 text-center',
                })}
              >
                <input {...getInputProps()} />
                <PhotoIcon className="mx-auto size-12 text-gray-300" />

                <p className="mt-4 text-sm/6 text-gray-600 text-center">
                  {strings.photos.dropzone.selectPhotos.firstRow}
                </p>
                <CommonButtonDefault sm secondary onClick={open} className="mx-auto mt-3">
                  {strings.photos.dropzone.selectPhotos.button}
                </CommonButtonDefault>
              </div>
            </div>
          </div>
        </div>
        {errors &&
          errors?.length > 0 &&
          errors.map((error: SimpleObject, index: number) => (
            <div key={`alert-${index + 1}`} className="my-5 text-left">
              <AlertPrimary type="danger" size="xs" title={error.message} />
            </div>
          ))}

        {photos.length > 0 && (
          <aside className="mt-5">
            <ul className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-5 sm:gap-y-5 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
              {photos.map((file, key) => (
                <li key={`file-${key + 1}`}>
                  <div>
                    <div className="relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
                      <div className="relative">
                        <img
                          alt={file.file.name}
                          src={file.preview}
                          className="aspect-[3/4] w-full bg-gray-200 object-cover sm:aspect-auto md:h-40 2xl:h-44 z-1"
                        />
                        <CommonButtonDefault
                          secondary
                          className="absolute top-1 right-1 z-10 !px-2.5"
                          onClick={() => onRemoveFile(file.file)}
                        >
                          <TrashIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400 hover:text-red-600"
                            aria-hidden="true"
                          />
                        </CommonButtonDefault>
                      </div>
                      <div className="flex flex-1 flex-col space-y-2 p-3">
                        <h3 className="text-sm font-medium break-words">{file.file.name}</h3>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </aside>
        )}
      </section>
      {photos.length > 0 && (
        <div className="mt-5 flex justify-end">
          <CommonButtonDefault primary disabled={photos.length < 1} onClick={() => onSubmitFiles()}>
            {strings.photos.dropzone.buttons.continueWithPhotos}
          </CommonButtonDefault>
        </div>
      )}
    </div>
  );
}
PhotosDropzone.defaultProps = {
  albumId: null,
};

export default PhotosDropzone;
