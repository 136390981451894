import React, { createContext, useState, useCallback, useMemo } from 'react';

import { SimpleObject } from '../entities/simple-object';

interface ComponentProps {
  children: React.ReactNode;
}

export const ModalKeys = {
  Recommendations: 'Recommendations',
  FileUpload: 'FileUpload',
  CreateItem: 'CreateItem',
  EditItem: 'EditItem',
  PhotoPreview: 'PhotoPreview',
};

interface ContextValue {
  setVisible: (name: string, state?: boolean) => void;
  isVisible: (name: string) => boolean;
}

const ModalsContext = createContext<ContextValue>({
  setVisible: () => null,
  isVisible: () => false,
});

function ModalsContextProvider({ children }: ComponentProps) {
  const [isOpen, setOpen] = useState<SimpleObject<boolean>>({
    privacy: false,
    terms: false,
  });

  const setVisible = useCallback(
    (name: string, state = true) => {
      setOpen((prevState) => {
        return {
          ...prevState,
          [name]: state,
        };
      });
    },
    [setOpen],
  );

  const isVisible = useCallback(
    (name: string) => {
      return isOpen[name] || false;
    },
    [isOpen],
  );

  const contextValue = useMemo(
    () => ({
      setVisible,
      isVisible,
    }),
    [setVisible, isVisible],
  );

  return <ModalsContext.Provider value={contextValue}>{children}</ModalsContext.Provider>;
}

export { ModalsContext, ModalsContextProvider };
