import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import * as companiesService from '../../services/api/companies';
import { Company } from '../../entities/company';
import CompaniesTableComponent from '../../components/companies/table';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import CompaniesFilter from '../../components/companies/filter';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { strings } from '../../localization/strings';
import { Pagination } from '../../entities/pagination';
import { SimpleObject } from '../../entities/simple-object';
import FilterCompanies from '../../entities/filter/companies';
import { fillFromParams } from '../../services/crud/filter';
import CommonButtonDefault from '../../components/common/buttons/default';
import ModalContent from '../../components/modals/content';
import CompanyCreateForm from './components/company-create-form';

function CompaniesListPageComponent() {
  const [queryParams, setQueryParams] = useSearchParams();

  const initialState: FilterCompanies = {
    page: 0,
    sort: '-created_at',
    is_active: [1, 0],
    is_valid_subscription: 1,
  };

  const types = {
    isNumeric: ['page', 'is_active', 'is_valid_subscription'],
    isDate: [
      'valid_to_start',
      'valid_to_end',
      'pressrelease_creation_valid_to_start',
      'pressrelease_creation_valid_to_end',
    ],
    isArray: ['is_active', 'service'],
  };

  const { token } = useAuth();
  const [params, setParams] = useState<FilterCompanies>(
    fillFromParams<FilterCompanies>(initialState, queryParams, types),
  );
  const [pagination, setPagination] = useState<Pagination>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [orders, setOrders] = useState<Company[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const items = await companiesService.list(token, params);

        setOrders(items.items);
        setPagination(items.pagination);
        setIsLoading(false);
      } catch (e) {
        // handle your error
        setIsLoading(false);
        setError((e as Error).message);
      }
    };

    fetchData();
  }, [token, params]);

  const setFilterData = (filterData: FilterCompanies) => {
    const q: SimpleObject = {};

    // Cleanup undefined and null values. We don't want them in URLs
    for (const [key, value] of Object.entries(filterData)) {
      if (typeof value !== 'undefined' && value !== null) {
        q[key] = value;
      }
    }

    setParams({ ...filterData, page: 0 });
    setQueryParams({ ...q, page: 0 } as SimpleObject);
  };

  const updatePage = (currentPage: number, newSort: string) => {
    setParams({ ...params, page: currentPage, sort: newSort });
    setQueryParams({ ...params, page: currentPage, sort: newSort } as SimpleObject);
  };

  const onDisplayModal = () => {
    setIsModalVisible(true);
  };

  return (
    <div>
      <header className="mb-3">
        <div className="flex space-x-3 justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">{strings.navigation.companies}</h1>
          <div>
            <CommonButtonDefault type="button" primary onClick={() => onDisplayModal()}>
              {strings.button.create}
            </CommonButtonDefault>
          </div>
        </div>
      </header>
      <main className="w-full">
        <div className="sm:rounded-lg border border-gray-200 overflow-hidden">
          {error && (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
              <AlertPrimary type="danger" text={error} />
            </div>
          )}
          <CompaniesFilter onUpdate={setFilterData} initialState={initialState} filterState={params} />
          {isLoading ? (
            <CommonAnimatedLoader />
          ) : (
            <>
              {orders.length > 0 && pagination && (
                <CompaniesTableComponent
                  orders={orders}
                  sort={params.sort || ''}
                  pagination={pagination}
                  onUpdate={updatePage}
                />
              )}
              {orders.length === 0 && (
                <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 text-xl">{strings.search.noResults}</div>
              )}
            </>
          )}
        </div>
      </main>

      {isModalVisible && (
        <ModalContent visible onCloseClick={() => setIsModalVisible(false)} title="Sukurti įmonę">
          <div className="min-w-96 pt-5">
            <CompanyCreateForm onCloseForm={() => setIsModalVisible(false)} />
          </div>
        </ModalContent>
      )}
    </div>
  );
}

export default CompaniesListPageComponent;
