import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { strings } from '../../../localization/strings';
import CommonButtonDefault from '../../../components/common/buttons/default';
import CommonConfirmationModal from '../../../components/modals/confirmation';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import PhotoComponent from './item';
import PaginationComponent from '../../../components/common/pagination';
import ModalContent from '../../../components/modals/content';
import { ModalKeys } from '../../../context/modal';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { parameters } from '../../../constants/parameters';
import { SimpleObject } from '../../../entities/simple-object';
import { Photo, PhotoEdit } from '../../../entities/photo';
import * as photosService from '../../../services/api/photos';
import { downloadPhoto } from '../../../services/crud/downloadPhoto';
import { useModal } from '../../../hooks/use-modal';
import { useAuth } from '../../../hooks/use-auth';
import FilterPhotos from '../../../entities/filter/photo';
import { Pagination } from '../../../entities/pagination';

interface ComponentProps {
  params: FilterPhotos;
  localPhotos: { items: Photo[]; pagination: Pagination } | undefined;
  isLocalPhotosLoading: boolean;
  isLocalPhotosFetching: boolean;
  onUpdatePage: (page: number) => void;
  albumId?: string;
}

function PhotoList({
  params,
  localPhotos,
  isLocalPhotosLoading,
  isLocalPhotosFetching,
  onUpdatePage,
  albumId,
}: ComponentProps) {
  const [photoIsLoading, setPhotoIsLoading] = useState<boolean>(true);
  const [photoError, setPhotoError] = useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<number[]>([]);
  const [photoPreviewKey, setPhotoPreviewKey] = useState<number>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorCommon, setErrorCommon] = useState<string>();

  const { token } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { setVisible } = useModal();

  const onUpdateCheckbox = (itemId: number, isChecked?: boolean) => {
    if (isChecked) {
      setSelectedPhotos([...selectedPhotos, itemId]);
      setSearchParams({ ...params, ids: [...selectedPhotos, itemId] } as SimpleObject);
    } else {
      selectedPhotos.splice(selectedPhotos.indexOf(itemId), 1);

      setSelectedPhotos([...selectedPhotos]);
      setSearchParams({ ...params, ids: [...selectedPhotos] } as SimpleObject);
    }
  };

  const createRouteWithParams = (route: string) => {
    if (selectedPhotos && selectedPhotos.length > 0) {
      const idsParams = selectedPhotos
        .map((id) => `ids=${encodeURIComponent(id)}`) // Encode each ID
        .join('&'); // Join with &

      navigate({
        pathname: route,
        search: idsParams,
      });
    } else {
      navigate({
        pathname: route,
      });
    }
  };

  const { mutate: updateMutation, isLoading: isUpdating } = useMutation({
    mutationFn: (data: PhotoEdit[]) => photosService.update(token, data),
    onSuccess: async () => {
      if (albumId) {
        await queryClient.invalidateQueries({ queryKey: ['albumPhotos', params] });
      } else {
        await queryClient.invalidateQueries({ queryKey: ['localPhotos', params] });
      }
    },
  });

  const { mutate: deletePhotoMutation, isLoading: isDeleting } = useMutation({
    mutationFn: (item: number) => {
      return photosService.deletePhoto(token, item);
    },
    onSuccess: async () => {
      if (albumId) {
        await queryClient.invalidateQueries({ queryKey: ['albumPhotos', params] });
      } else {
        await queryClient.invalidateQueries({ queryKey: ['localPhotos', params] });
      }
    },
  });

  const onPublishPhoto = (item: PhotoEdit) => {
    const updatedItem = {
      id: item.id,
      original_id: item.original_id,
      is_published: 1,
      country: item.country,
      content: item.content,
      title: item.title,
      author_id: item.author_id,
      keywords: item.keywords,
      category: item.category,
      subcategory: item.subcategory,
      lang: item.lang,
    };
    const updatedData = [];

    updatedData.push(updatedItem);

    updateMutation(updatedData);
  };

  const onDeletePhotos = () => {
    setConfirmDelete(false);

    if (selectedPhotos && selectedPhotos.length > 0) {
      for (const item of selectedPhotos) {
        deletePhotoMutation(item);
      }
    }

    searchParams.delete('ids');
    setSearchParams(searchParams);
  };

  const showDeleteModal = (itemId: number) => {
    setSelectedPhotos([itemId]);
    setConfirmDelete(true);
  };

  const onShowPhoto = (show: boolean, index: number) => {
    if (show) {
      setPhotoPreviewKey(index);
      setVisible(ModalKeys.PhotoPreview, true);
    }
  };

  const handleLoad = () => setPhotoIsLoading(false);
  const handleError = () => {
    setPhotoIsLoading(false);
    setPhotoError(true);
  };

  const onDownloadFile = async (id: number) => {
    await downloadPhoto(token, id, setIsDownloading, setErrorCommon);
  };

  return (
    <>
      {errorCommon && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary type="danger" text={errorCommon} />
        </div>
      )}
      {selectedPhotos.length > 0 && (
        <div className="bg-white sm:rounded-lg border border-gray-200 px-4 text-sm sm:px-6 lg:px-8 py-4 mt-4">
          <div className="max-w-7xl flex space-x-4 items-center">
            <div>
              {strings.album.filter.selected} {selectedPhotos.length}
            </div>
            <CommonButtonDefault secondary sm onClick={() => createRouteWithParams('/photos/local/edit')}>
              {strings.button.edit}
            </CommonButtonDefault>

            <CommonButtonDefault secondary sm onClick={() => createRouteWithParams('/photos/local/translate')}>
              {strings.button.translate}
            </CommonButtonDefault>
            <CommonButtonDefault secondary sm onClick={() => setConfirmDelete(true)}>
              {strings.button.delete}
            </CommonButtonDefault>
            <CommonButtonDefault secondary sm>
              {strings.button.publish}
            </CommonButtonDefault>
          </div>
        </div>
      )}
      <CommonConfirmationModal
        open={confirmDelete}
        confirmed={() => onDeletePhotos()}
        cancelled={() => setConfirmDelete(false)}
        title={strings.photos.modal.deletePhotos.title}
        description={strings.photos.modal.deletePhotos.text}
        buttonConfirm={strings.common.yes}
        buttonCancel={strings.common.no}
      />
      {isLocalPhotosLoading || isDeleting || isLocalPhotosFetching || isUpdating ? (
        <CommonAnimatedLoader />
      ) : (
        <div className="py-5">
          <h2 className="sr-only">{strings.navigation.photos}</h2>
          {localPhotos && localPhotos?.items.length > 0 ? (
            <>
              <div className="grid grid-cols-1 gap-y-3 sm:grid-cols-3 sm:gap-x-3 sm:gap-y-3 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-8 mb-4">
                {localPhotos?.items.map((item, index) => (
                  <div tabIndex={0} role="button" key={item.id}>
                    <PhotoComponent
                      item={item}
                      onDeleteItem={showDeleteModal}
                      onUpdateCheckbox={onUpdateCheckbox}
                      onPublishPhoto={onPublishPhoto}
                      photoPreview={(photoPreview) => onShowPhoto(photoPreview, index)}
                      onDownloadPhoto={onDownloadFile}
                      isDownloading={isDownloading}
                    />
                  </div>
                ))}
              </div>
              <PaginationComponent
                hasBg={false}
                pagination={localPhotos?.pagination}
                onChangePage={(page) => onUpdatePage(page)}
              />
              <ModalContent
                wide
                type={ModalKeys.PhotoPreview}
                onCloseClick={() => setVisible(ModalKeys.PhotoPreview, false)}
                title={photoPreviewKey !== undefined ? localPhotos.items[photoPreviewKey].title : ''}
              >
                <div className="pb-5 relative">
                  {photoIsLoading && (
                    <div className="w-full h-full absolute left-0 flex justify-center items-center">
                      <CommonAnimatedSpinner />
                    </div>
                  )}
                  {photoError && (
                    <div className="w-full h-full absolute left-0 flex justify-center items-center">
                      <AlertPrimary type="danger" title="Something went wrong. Unable to show image" />
                    </div>
                  )}
                  {photoPreviewKey !== undefined && (
                    <>
                      <div className="mt-2">
                        <img
                          alt=""
                          src={`${parameters.websiteUrl}/lt/nuotraukos/${localPhotos.items[photoPreviewKey].id}/rodyti`}
                          className="object-contain w-auto bg-gray-200 mx-auto"
                          onLoad={handleLoad}
                          onError={handleError}
                        />
                      </div>
                      <div className="mt-4 text-sm">
                        {strings.photos.edit.form.keywords}: {localPhotos.items[photoPreviewKey].keywords}
                      </div>
                    </>
                  )}
                </div>
                <CommonButtonDefault
                  className="absolute left-[-34px] lg:left-[-45px] top-[50%] mt-[-15px]"
                  disabled={photoPreviewKey === 0}
                  transparent
                  sm
                  onClick={() =>
                    setPhotoPreviewKey(photoPreviewKey !== undefined && photoPreviewKey > 0 ? photoPreviewKey - 1 : 0)
                  }
                >
                  <ChevronLeftIcon width={30} height={30} />
                  <span className="sr-only">back</span>
                </CommonButtonDefault>
                <CommonButtonDefault
                  className="absolute right-[-34px] lg:right-[-45px] top-[50%] mt-[-15px]"
                  disabled={photoPreviewKey === localPhotos.items.length - 1}
                  transparent
                  sm
                  onClick={() =>
                    setPhotoPreviewKey(
                      photoPreviewKey !== undefined && photoPreviewKey + 1 < localPhotos.items.length
                        ? photoPreviewKey + 1
                        : photoPreviewKey,
                    )
                  }
                >
                  <ChevronRightIcon width={30} height={30} />
                  <span className="sr-only">next</span>
                </CommonButtonDefault>
              </ModalContent>
            </>
          ) : (
            <div>{strings.photos.list.empty}</div>
          )}
        </div>
      )}
    </>
  );
}

PhotoList.defaultProps = {
  albumId: null,
};

export default PhotoList;
