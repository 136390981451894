import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Pagination } from '../../entities/pagination';
import * as genericApi from './generic-api';
import { Album } from '../../entities/album';

export const list = async (
  token: string,
  paramString?: object,
): Promise<{ items: Album[]; pagination: Pagination }> => {
  const url = `${parameters.api.baseUrl}/albums?with_photo=1&count[]=photos`;

  return genericApi.list<Album>(token, url, paramString);
};

export const create = async (token: string, data: Partial<Album>): Promise<Album | undefined> => {
  const url = `${parameters.api.baseUrl}/albums`;

  return genericApi.create<Album, Album>(token, url, data);
};

export const one = async (token: string, id: number): Promise<Album> => {
  const url = `${parameters.api.baseUrl}/albums/${id}`;

  return genericApi.one<Album>(token, url);
};

export const update = async (token: string, id: number, data: Partial<Album>): Promise<Album | undefined> => {
  const url = `${parameters.api.baseUrl}/albums/${id}`;

  return genericApi.update<Album, Album>(token, url, data);
};

export const deleteAlbum = async (
  token: string,
  id: number,
  deleteAll?: number,
): Promise<{
  status: string;
}> => {
  let url = `${parameters.api.baseUrl}/albums/${id}`;

  if (deleteAll) {
    url += '?with_photos=1';
  }

  try {
    const result = await axios.delete(
      url,

      { headers: { Authorization: `Bearer ${token}` } },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};
