import React, { useState } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../../../hooks/use-auth';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { strings } from '../../../localization/strings';
import { Pagination } from '../../../entities/pagination';
import { SimpleObject } from '../../../entities/simple-object';
import { fillFromParams } from '../../../services/crud/filter';
import * as photosService from '../../../services/api/photos';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { useModal } from '../../../hooks/use-modal';
import { ModalKeys } from '../../../context/modal';
import ModalContent from '../../../components/modals/content';
import { parameters } from '../../../constants/parameters';
import { Photo } from '../../../entities/photo';
import PhotosFilter from '../components/filter';
import PhotoComponent from '../components/item';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import PaginationComponent from '../../../components/common/pagination';
import FilterPhotos from '../../../entities/filter/photo';
import { downloadPhoto } from '../../../services/crud/downloadPhoto';

function PhotosForeignPageComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isLocalPhotosPage = location.pathname.includes('local');

  const initialState: FilterPhotos = {
    page: 0,
    sort: '-created_at',
    agency_id: 4,
    is_foreign: 'true',
    // language_id: 0,
  };

  const types = {
    isNumeric: ['page', 'language_id', 'agency_id'],
    isDate: ['valid_to_start', 'valid_to_end'],
    isArray: ['ids', 'author_id'],
  };

  const { token } = useAuth();
  const [params, setParams] = useState<FilterPhotos>(fillFromParams<FilterPhotos>(initialState, searchParams, types));
  const [photoIsLoading, setPhotoIsLoading] = useState<boolean>(true);
  const [photoError, setPhotoError] = useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<number[]>([]);
  const [photoPreviewKey, setPhotoPreviewKey] = useState<number>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [errorCommon, setErrorCommon] = useState<string>();

  const { setVisible } = useModal();

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching } = useQuery<{ items: Photo[]; pagination: Pagination }, Error>({
    queryKey: ['foreignPhotos', params],
    queryFn: () => photosService.list(token, params),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });

  const setFilterData = (filterData: FilterPhotos) => {
    const q: SimpleObject = {};

    // Cleanup undefined and null values. We don't want them in URLs
    for (const [key, value] of Object.entries(filterData)) {
      if (typeof value !== 'undefined' && value !== null) {
        q[key] = value;
      }
    }

    setParams({ ...filterData, page: 0 });
    setSearchParams({ ...q, page: 0 } as SimpleObject);
    queryClient.invalidateQueries({ queryKey: ['foreignPhotos', params] });
  };

  const updatePage = (currentPage: number, newSort?: string) => {
    setParams({ ...params, page: currentPage, sort: newSort });
    setSearchParams({ ...params, page: currentPage, sort: newSort } as SimpleObject);
  };

  const onUpdateCheckbox = (itemId: number, isChecked?: boolean) => {
    if (isChecked) {
      setSelectedPhotos([...selectedPhotos, itemId]);
      setSearchParams({ ...params, ids: [...selectedPhotos, itemId] } as SimpleObject);
    } else {
      selectedPhotos.splice(selectedPhotos.indexOf(itemId), 1);

      setSelectedPhotos([...selectedPhotos]);
      setSearchParams({ ...params, ids: [...selectedPhotos] } as SimpleObject);
    }
  };

  const onShowPhoto = (show: boolean, index: number) => {
    if (show) {
      setPhotoPreviewKey(index);
      setVisible(ModalKeys.PhotoPreview, true);
    }
  };

  const handleLoad = () => setPhotoIsLoading(false);
  const handleError = () => {
    setPhotoIsLoading(false);
    setPhotoError(true);
  };

  const onDownloadFile = async (id: number) => {
    await downloadPhoto(token, id, setIsDownloading, setErrorCommon);
  };

  return (
    <div>
      <header className="mb-3">
        <div className="flex space-x-3 justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">{strings.navigation.photosforeignAgencies}</h1>
          {isLocalPhotosPage && (
            <div>
              <Link
                className="block text-base rounded-lg py-2 px-4 font-medium bg-blue-800 text-white hover:bg-blue-900 no-underline"
                to="upload"
              >
                {strings.album.buttons.addPhotos}
              </Link>
            </div>
          )}
        </div>
      </header>

      <main className="w-full">
        {error && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
            <AlertPrimary type="danger" text={error.message} />
          </div>
        )}
        {errorCommon && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
            <AlertPrimary type="danger" text={errorCommon} />
          </div>
        )}

        <PhotosFilter isForeign onUpdate={setFilterData} initialState={initialState} filterState={params} />

        {isLoading || isFetching ? (
          <CommonAnimatedLoader />
        ) : (
          <div className="py-5">
            <h2 className="sr-only">{strings.navigation.photos}</h2>
            {data && data?.items.length > 0 ? (
              <>
                <div className="grid grid-cols-1 gap-y-3 sm:grid-cols-3 sm:gap-x-3 sm:gap-y-3 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-8 mb-4">
                  {data?.items.map((item, index) => (
                    <div tabIndex={0} role="button" key={item.id}>
                      <PhotoComponent
                        isForeign
                        item={item}
                        onUpdateCheckbox={onUpdateCheckbox}
                        photoPreview={(photoPreview) => onShowPhoto(photoPreview, index)}
                        onDownloadPhoto={onDownloadFile}
                        isDownloading={isDownloading}
                      />
                    </div>
                  ))}
                </div>
                <PaginationComponent
                  hasBg={false}
                  pagination={data?.pagination}
                  onChangePage={(page) => updatePage(page)}
                />
                <ModalContent
                  wide
                  type={ModalKeys.PhotoPreview}
                  onCloseClick={() => setVisible(ModalKeys.PhotoPreview, false)}
                  title={photoPreviewKey !== undefined ? data.items[photoPreviewKey].title : ''}
                >
                  <div className="pb-5 relative">
                    {photoIsLoading && (
                      <div className="w-full h-full absolute left-0 flex justify-center items-center">
                        <CommonAnimatedSpinner />
                      </div>
                    )}
                    {photoError && (
                      <div className="w-full h-full absolute left-0 flex justify-center items-center">
                        <AlertPrimary type="danger" title="Something went wrong. Unable to show image" />
                      </div>
                    )}
                    {photoPreviewKey !== undefined && (
                      <>
                        <div className="mt-2">
                          <img
                            alt=""
                            src={`${parameters.websiteUrl}/lt/nuotraukos/${data.items[photoPreviewKey].id}/rodyti`}
                            className="object-contain w-auto bg-gray-200 mx-auto"
                            onLoad={handleLoad}
                            onError={handleError}
                          />
                        </div>
                        <div className="mt-4 text-sm">
                          {strings.photos.edit.form.keywords}: {data.items[photoPreviewKey].keywords}
                        </div>
                      </>
                    )}
                  </div>
                  <CommonButtonDefault
                    className="absolute left-[-34px] lg:left-[-45px] top-[50%] mt-[-15px]"
                    disabled={photoPreviewKey === 0}
                    transparent
                    sm
                    onClick={() =>
                      setPhotoPreviewKey(photoPreviewKey !== undefined && photoPreviewKey > 0 ? photoPreviewKey - 1 : 0)
                    }
                  >
                    <ChevronLeftIcon width={30} height={30} />
                    <span className="sr-only">back</span>
                  </CommonButtonDefault>
                  <CommonButtonDefault
                    className="absolute right-[-34px] lg:right-[-45px] top-[50%] mt-[-15px]"
                    disabled={photoPreviewKey === data.items.length - 1}
                    transparent
                    sm
                    onClick={() =>
                      setPhotoPreviewKey(
                        photoPreviewKey !== undefined && photoPreviewKey + 1 < data.items.length
                          ? photoPreviewKey + 1
                          : photoPreviewKey,
                      )
                    }
                  >
                    <ChevronRightIcon width={30} height={30} />
                    <span className="sr-only">next</span>
                  </CommonButtonDefault>
                </ModalContent>
              </>
            ) : (
              <div>{strings.photos.list.empty}</div>
            )}
          </div>
        )}
      </main>
    </div>
  );
}

export default PhotosForeignPageComponent;
