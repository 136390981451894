import React, { useState } from 'react';
import { Disclosure, Menu } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import DatePicker from 'react-datepicker';
import { diff } from 'deep-object-diff';
import Select from 'react-select';

import { SimpleObject } from '../../../entities/simple-object';
import { useDebounce } from '../../../hooks/use-debounce';
import { fromJsDate } from '../../../services/formatter/date';
import { pluralizeWithNumber } from '../../../services/formatter/pluralize';
import { strings } from '../../../localization/strings';
import { CompanyAdminStructure, Language } from '../../../entities/company-admin-structure';
import FilterPhotos from '../../../entities/filter/photo';
import { Category } from '../../../entities/category';
import { Author } from '../../../entities/author';

interface ComponentProps {
  onUpdate: (filters: FilterPhotos) => void;
  initialState: FilterPhotos;
  filterState: FilterPhotos;
  categories?: CompanyAdminStructure;
  authors?: Author[];
  isForeign?: boolean;
}

function PhotosFilter({ onUpdate, initialState, filterState, categories, authors, isForeign }: ComponentProps) {
  const filters = {
    is_published: [
      { value: 1, label: strings.photos.filter.state.active },
      { value: 0, label: strings.photos.filter.state.inactive },
    ],
  };
  const [dateValue, setDateValue] = useState<SimpleObject<Date | null>>({
    valid_to_start: filterState.valid_to_start || null,
    valid_to_end: filterState.valid_to_end || null,
  });

  const activeFilters = Object.keys(diff(initialState, filterState)).length;

  const [inputValue, setInputValue] = useState<SimpleObject<string>>({
    search: filterState.search || '',
    // Add new inputs to the same state
  });

  const onDelayedTextChange = useDebounce((name: keyof FilterPhotos, value: string) => {
    onUpdate({ ...filterState, [name]: value });
  }, 500);

  const onTextChange = (name: keyof FilterPhotos, value: string) => {
    setInputValue({ ...inputValue, [name]: value });
    onDelayedTextChange(name, value);
  };

  const onChangeDate = (startFieldName: string, endFieldName: string, date: [Date | null, Date | null]) => {
    setDateValue({ ...dateValue, [startFieldName]: date[0], [endFieldName]: date[1] });

    if (date[0] && date[1]) {
      onUpdate({ ...filterState, [startFieldName]: fromJsDate(date[0]), [endFieldName]: fromJsDate(date[1]) });
    }

    if (!date[0] && !date[1]) {
      onUpdate({ ...filterState, [startFieldName]: null, [endFieldName]: null });
    }
  };

  // const onRadioChange = (name: keyof FilterAlbums, value: number | string) => {
  //   onUpdate({ ...filterState, [name]: value });
  // };

  const onCheckboxChange = (name: keyof FilterPhotos, value: number | string, checked: boolean) => {
    const selected = (filterState[name] as typeof value[]) || [];

    if (Array.isArray(selected)) {
      const index = selected.indexOf(value);

      if (checked) {
        if (index === -1) {
          selected.push(value);
        }
      } else {
        selected.splice(index, 1);
      }
    }

    onUpdate({ ...filterState, [name]: selected });
  };

  const onSelectChange = (name: keyof FilterPhotos, value: string | number | undefined | number[]) => {
    let filterData = filterState;

    if (name === 'language_id') {
      filterData = { ...filterState, category_id: undefined };
    }

    onUpdate({ ...filterData, [name]: value });
  };

  const clearFilter = () => {
    // Reset date values that are no in the initial state
    const updatedDateValue = { ...dateValue };

    for (const [key, value] of Object.entries(dateValue)) {
      if (value !== null && !initialState[key as keyof FilterPhotos]) {
        updatedDateValue[key] = null;
      }
    }
    setDateValue(updatedDateValue);

    // Reset input values that are no in the initial state
    const updatedInputValue = { ...inputValue };

    for (const [key, value] of Object.entries(inputValue)) {
      if (value !== '' && !initialState[key as keyof FilterPhotos]) {
        updatedInputValue[key] = '';
      }
    }
    setInputValue(updatedInputValue);

    onUpdate({ ...initialState });
  };

  const getAllCategories = (languageId: number) => {
    if (!categories?.languages[languageId]) {
      return [];
    }

    return [...categories.languages[languageId].categories, ...categories.languages[languageId].guideCategories];
  };

  return (
    <div className="bg-white">
      {/* Filters */}
      <Disclosure as="section" aria-labelledby="filter-heading" className="grid items-center border-b border-gray-200">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1 py-3">
          <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
            <div>
              <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                <FunnelIcon
                  className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                {activeFilters > 0
                  ? pluralizeWithNumber(activeFilters, strings.companies.filter.activeFilters)
                  : strings.companies.filter.select}
              </Disclosure.Button>
            </div>
            <div className="pl-6">
              {activeFilters > 0 && (
                <button type="button" onClick={clearFilter} className="text-gray-500">
                  {strings.filter.clearFilter}
                </button>
              )}
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-200 py-5">
          <div className="mx-auto max-w-7xl gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-4 md:gap-x-6">
              {!isForeign && (
                <>
                  <fieldset>
                    <legend className="block font-medium">{strings.photos.filter.state.title}</legend>
                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                      {filters.is_published.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center text-base sm:text-sm">
                          <input
                            id={`is_published-${optionIdx}`}
                            name="is_published[]"
                            defaultValue={option.value}
                            type="checkbox"
                            onChange={(event) => onCheckboxChange('is_published', option.value, event.target.checked)}
                            className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            defaultChecked={filterState?.is_published?.indexOf(option.value) !== -1}
                          />
                          <label htmlFor={`is_published-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600">
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend className="block font-medium">{strings.photos.filter.category.title}</legend>
                    <div className="sm:mt-4">
                      {categories && categories.languages && (
                        <Select
                          options={categories.languages}
                          placeholder={strings.photos.filter.language}
                          styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                          isClearable
                          getOptionValue={(option: Language) => `${option.id}`}
                          getOptionLabel={(option: Language) => `${option.name}`}
                          value={categories.languages.find((opt) => filterState.language_id === opt.id)}
                          onChange={(e) => {
                            onSelectChange('language_id', e?.id);
                          }}
                        />
                      )}
                      {filterState?.language_id !== undefined && (
                        <Select
                          key={filterState?.language_id}
                          className="w-50 mt-3"
                          isClearable
                          styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                          options={getAllCategories(filterState?.language_id)}
                          getOptionValue={(option: Category) => `${option.id}`}
                          getOptionLabel={(option: Category) => `${option.name}`}
                          placeholder={strings.photos.edit.form.placeholder.category}
                          value={getAllCategories(filterState?.language_id).find(
                            (opt) => opt.id === filterState.category_id,
                          )}
                          onChange={(e) => {
                            onSelectChange('category_id', e?.id);
                          }}
                        />
                      )}
                    </div>
                  </fieldset>
                </>
              )}
              <fieldset>
                <legend className="block font-medium">{strings.photos.filter.date}</legend>
                <div className="sm:mt-4">
                  <DatePicker
                    monthsShown={2}
                    showYearDropdown
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md z-999"
                    selectsRange
                    startDate={dateValue.valid_to_start}
                    selected={dateValue.valid_to_start}
                    endDate={dateValue.valid_to_end}
                    onChange={(date) => onChangeDate('valid_to_start', 'valid_to_end', date)}
                  />
                </div>
              </fieldset>
              {!isForeign && (
                <fieldset>
                  <legend className="block font-medium">{strings.photos.filter.author}</legend>
                  <div className="sm:mt-4">
                    {authors && (
                      <Select
                        isMulti
                        isClearable
                        className="w-50 mt-3"
                        styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                        options={authors}
                        getOptionValue={(option: Author) => `${option.id}`}
                        getOptionLabel={(option: Author) =>
                          `${option.first_name || ''} ${option.last_name || ''} ${option.id || ''}`
                        }
                        placeholder={strings.photos.edit.form.placeholder.author}
                        value={
                          filterState?.author_id && filterState?.author_id?.length > 0
                            ? [...authors.filter((s) => filterState?.author_id?.indexOf(s.id) !== -1)]
                            : []
                        }
                        onChange={(e) => {
                          const authorsIds = [];

                          for (const author of e) {
                            authorsIds.push(author.id);
                          }

                          onSelectChange('author_id', authorsIds);
                        }}
                      />
                    )}
                  </div>
                </fieldset>
              )}
            </div>
          </div>
        </Disclosure.Panel>
        <div className="col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
            <Menu as="div" className="relative w-96">
              <input
                id="search"
                name="search"
                className="block w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                placeholder={strings.common.searchLabel}
                type="search"
                onChange={(event) => onTextChange('search', event.target.value)}
                value={inputValue.search}
              />
            </Menu>
          </div>
        </div>
      </Disclosure>
    </div>
  );
}

PhotosFilter.defaultProps = {
  isForeign: false,
  categories: {},
  authors: [],
};

export default PhotosFilter;
