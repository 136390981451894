import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { PhotoIcon } from '@heroicons/react/20/solid';

import CommonButtonDefault from '../../../components/common/buttons/default';
import Badge from '../../../components/common/badge';
import { strings } from '../../../localization/strings';
import { fromTimestamp, fromTimestampFromSeconds } from '../../../services/formatter/date';
import { Photo, PhotoEdit } from '../../../entities/photo';
import { parameters } from '../../../constants/parameters';

interface ComponentProps {
  item: Photo;
  onUpdateCheckbox: (id: number, checked: boolean) => void;
  photoPreview: (showPhoto: boolean) => void;
  onDeleteItem?: (id: number) => void;
  onPublishPhoto?: (item: PhotoEdit) => void;
  onDownloadPhoto: (id: number) => void;
  isDownloading: boolean;
  isForeign?: boolean;
}

function PhotoComponent({
  item,
  onUpdateCheckbox,
  photoPreview,
  onDeleteItem,
  onPublishPhoto,
  onDownloadPhoto,
  isDownloading,
  isForeign,
}: ComponentProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const onDisplayenu = (show: boolean) => {
    setShowMenu(show);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div key={item.id} className="relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
      <div className="relative">
        <CommonButtonDefault
          transparent
          className="no-underline !p-0 w-full text-center h-[180px] md:h-40 2xl:h-42 !bg-gray-100"
          onClick={() => photoPreview(true)}
        >
          {item?.preview_photo ? (
            <img
              alt={item.title}
              src={`${parameters.websiteUrl}/preview/${item?.preview_photo}`}
              className="object-cover object-center mx-auto"
            />
          ) : (
            <span className="h-full flex items-center">
              <PhotoIcon className="text-gray-300 m-auto" width={60} height={60} />
            </span>
          )}
        </CommonButtonDefault>
        {!isForeign && (
          <div className="absolute z-10 top-1 left-1">
            <input
              id={`photo-${item.id}`}
              name={`photo-${item.id}`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onChange={(e) => onUpdateCheckbox(item.id, e.target.checked)}
            />
          </div>
        )}
        <div className="absolute z-60 top-1 right-1">
          <CommonButtonDefault secondary className="!px-2 !py-1.5" onClick={() => onDisplayenu(!showMenu)}>
            <Cog6ToothIcon width={20} height={20} />
          </CommonButtonDefault>
          {showMenu && (
            <div
              ref={menuRef}
              className="absolute right-1 !text-left text-sm flex flex-col space-y-2 bg-white py-2 px-3 z-60 ring-1 ring-inset ring-gray-300 rounded-b-md rounded-ss-md"
            >
              {!isForeign && (
                <Link
                  className="text-gray-900 no-underline hover:text-blue-500"
                  to={`/photos/local/edit?ids=${item.id}`}
                >
                  {strings.button.edit}
                </Link>
              )}
              <CommonButtonDefault
                sm
                menulink
                onClick={() => {
                  photoPreview(true);
                  onDisplayenu(!showMenu);
                }}
              >
                {strings.button.preview}
              </CommonButtonDefault>
              {!isForeign && (
                <Link
                  className="text-gray-900 no-underline hover:text-blue-500"
                  to={`/photos/local/translate?ids=${item.id}`}
                >
                  {strings.button.translate}
                </Link>
              )}
              <CommonButtonDefault
                disabled={isDownloading}
                sm
                menulink
                onClick={() => {
                  onDownloadPhoto(item.id);
                }}
              >
                {isDownloading ? strings.button.downloading : strings.button.download}
                {}
              </CommonButtonDefault>
              {!item.published_at && onPublishPhoto && !isForeign && (
                <CommonButtonDefault sm menulink onClick={() => onPublishPhoto(item)}>
                  {strings.button.publish}
                </CommonButtonDefault>
              )}
              {!isForeign && onDeleteItem && (
                <CommonButtonDefault sm menulink onClick={() => onDeleteItem(item.id)}>
                  {strings.button.delete}
                </CommonButtonDefault>
              )}
            </div>
          )}
        </div>
        {!item.published_at && (
          <span className="absolute bottom-1 left-1 z-10">
            <Badge status="warning" text="Ruošiama" />
          </span>
        )}
      </div>
      <div>
        <CommonButtonDefault
          transparent
          className="text-gray-900 no-underline hover:text-indigo-600 text-left flex flex-1 flex-col space-y-2 !p-3"
          onClick={() => photoPreview(true)}
        >
          <h3 className="text-sm font-medium">{item.title}</h3>
          <div>
            <div className="text-xs text-gray-500">
              {strings.album.created} {fromTimestamp(item.created_at)}
            </div>
            <div className="text-xs text-gray-500">
              {strings.album.published} {item.published_at ? fromTimestampFromSeconds(item.published_at) : '-'}
            </div>
          </div>
        </CommonButtonDefault>
      </div>
    </div>
  );
}

PhotoComponent.defaultProps = {
  onPublishPhoto: () => null,
  onDeleteItem: () => null,
  isForeign: false,
};

export default PhotoComponent;
