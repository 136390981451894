import React from 'react';
import {
  Controller,
  useFieldArray,
  Control,
  UseFormWatch,
  FieldErrorsImpl,
  DeepRequired,
  GlobalError,
} from 'react-hook-form';
import Select from 'react-select';

import StyledLabel from '../../../components/common/form/styled-label';
import { strings } from '../../../localization/strings';
import { Category } from '../../../entities/category';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { EditPhotoFieldValues } from '../../../entities/form/edit-photo-form';

interface ComponentProps {
  fieldIndex: number;
  control: Control<EditPhotoFieldValues>;
  watch: UseFormWatch<EditPhotoFieldValues>;
  categoriesList: Category[];
  errors: Partial<FieldErrorsImpl<DeepRequired<EditPhotoFieldValues>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  };
}

export default function CategoriesFields({ fieldIndex, control, watch, categoriesList, errors }: ComponentProps) {
  const {
    fields: categoriesArray,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `photoRows.${fieldIndex}.categories` as `photoRows.0.categories`,
    keyName: 'fields_categories_id',
  });

  const findSubcategories = (index: number) => {
    const categories = categoriesList.find(
      (c) => c.id === watch(`photoRows.${fieldIndex}.categories.${index}.parent_id`),
    );

    return categories?.sub_category || [];
  };

  return (
    <div>
      {categoriesArray &&
        categoriesArray.map((item, index) => (
          <div key={item.fields_categories_id} className="grid grid-cols-5 mb-4 space-x-3 items-center">
            <div className="col-span-2">
              <StyledLabel>{strings.photos.edit.form.category}</StyledLabel>
              <Controller
                control={control}
                name={`photoRows.${fieldIndex}.categories.${index}.parent_id` as const}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    className="w-50"
                    styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                    options={categoriesList}
                    getOptionValue={(option: Category) => `${option.id}`}
                    getOptionLabel={(option: Category) => `${option.name}`}
                    placeholder={strings.photos.edit.form.placeholder.category}
                    value={categoriesList.find((opt) => opt.id === field.value)}
                    onChange={(e) => {
                      field.onChange(e?.id);
                    }}
                  />
                )}
              />

              {errors && errors.photoRows?.[fieldIndex]?.categories?.[index]?.parent_id && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {strings.form.error.categoryRequired}
                </p>
              )}
            </div>
            <div className="col-span-2">
              <StyledLabel>{strings.photos.edit.form.subcategory}</StyledLabel>

              {findSubcategories(index).length > 0 && (
                <Controller
                  key={`${watch(`photoRows.${fieldIndex}.categories.${index}.parent_id`)}`}
                  control={control}
                  name={`photoRows.${fieldIndex}.categories.${index}.id` as const}
                  render={({ field }) => (
                    <Select
                      isClearable
                      className="w-50"
                      styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                      options={findSubcategories(index)}
                      getOptionValue={(option: Category) => `${option.id}`}
                      getOptionLabel={(option: Category) => `${option.name}`}
                      placeholder={strings.photos.edit.form.placeholder.subcategory}
                      value={findSubcategories(index).find((opt) => opt.id === field.value)}
                      onChange={(e) => {
                        field.onChange(e?.id);
                      }}
                    />
                  )}
                />
              )}

              {findSubcategories(index).length === 0 && <div>Ši tema neturi potėmių</div>}
            </div>
            {categoriesArray.length > 1 && (
              <div>
                <CommonButtonDefault sm danger type="button" onClick={() => remove(index)}>
                  {strings.button.delete}
                </CommonButtonDefault>
              </div>
            )}
          </div>
        ))}
      <div className="mt-4">
        <CommonButtonDefault
          secondary
          sm
          type="button"
          onClick={() =>
            append({
              id: null, // stands for subcategory id
              parent_id: null, // stands for category id
            })
          }
        >
          {strings.photos.edit.form.addCategory}
        </CommonButtonDefault>
      </div>
    </div>
  );
}
