import React from 'react';
import { useRoutes, Outlet, Navigate } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import UsersLoginPageComponent from '../users/login';
import CompaniesListPageComponent from '../companies/list';
import LayoutHeadingComponent from '../../components/layout/heading';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import NotFoundPageComponent from '../text/not-found';
import CompaniesViewPageComponent from '../companies/view';
import UsersEditPageComponent from '../users/edit';
import AlbumsViewPageComponent from '../albums/view';
import AlbumsPageComponent from '../albums/list';
import PhotosPageComponent from '../photos/local/list';
import PhotosUploadPageComponent from '../photos/local/upload';
import PhotosEditPageComponent from '../photos/local/edit';
import PhotosTranslatePageComponent from '../photos/local/translate';
import PhotosForeignPageComponent from '../photos/foreign/list';
import AlbumsUploadPageComponent from '../albums/upload';

export default function Root() {
  const { user, loading } = useAuth();

  const isLoggedIn = !loading && user;

  const element = useRoutes([
    {
      path: '/',
      element: !isLoggedIn ? <Navigate to="/login" /> : <Outlet />,
      children: [
        {
          path: '/',
          element: <Navigate to="/companies" />,
        },
        {
          path: 'users',
          element: <Outlet />,
          children: [
            {
              path: 'create/:companyId',
              element: <UsersEditPageComponent />,
            },
            {
              path: 'edit/:id',
              element: <UsersEditPageComponent isEdit />,
            },
          ],
        },
        {
          path: 'companies',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <CompaniesListPageComponent />,
            },
            {
              path: 'view/:id',
              element: <CompaniesViewPageComponent />,
            },
          ],
        },
        {
          path: 'albums',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <AlbumsPageComponent />,
            },
            {
              path: 'upload/:id',
              element: <AlbumsUploadPageComponent />,
            },
            {
              path: 'edit/:id',
              element: <AlbumsPageComponent />,
            },
            {
              path: 'view/:id',
              element: <AlbumsViewPageComponent />,
            },
          ],
        },
        {
          path: 'photos',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <PhotosPageComponent />,
            },
            {
              path: 'local',
              children: [
                {
                  path: '',
                  element: <PhotosPageComponent />,
                },
                {
                  path: 'upload',
                  element: <PhotosUploadPageComponent />,
                },
                {
                  path: 'edit',
                  element: <PhotosEditPageComponent />,
                },
                {
                  path: 'translate',
                  element: <PhotosTranslatePageComponent />,
                },
              ],
            },
            {
              path: 'foreign',
              element: <PhotosForeignPageComponent />,
            },
          ],
        },
      ],
    },
    {
      path: '/login',
      element: isLoggedIn ? <Navigate to="/" /> : <UsersLoginPageComponent />,
    },
  ]);

  return (
    <div>
      {loading && <CommonAnimatedLoader />}
      {!loading && <LayoutHeadingComponent>{element || <NotFoundPageComponent />}</LayoutHeadingComponent>}
    </div>
  );
}
