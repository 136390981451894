import axios from 'axios';

import { parameters } from '../../constants/parameters';
import { Pagination } from '../../entities/pagination';
import * as genericApi from './generic-api';
import { Photo, PhotoEdit } from '../../entities/photo';
import { Author } from '../../entities/author';

export const list = async (
  token: string,
  paramString?: object,
): Promise<{ items: Photo[]; pagination: Pagination }> => {
  const url = `${parameters.api.baseUrl}/photos-crud?loads[]=categories`;

  return genericApi.list<Photo>(token, url, paramString);
};

export const update = async (token: string, photos: PhotoEdit[]): Promise<Photo[]> => {
  const url = `${parameters.api.baseUrl}/photos-crud/update`;

  try {
    const result = await axios.post(
      url,
      {
        photos,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const translate = async (token: string, photos: PhotoEdit[]): Promise<Photo[]> => {
  const url = `${parameters.api.baseUrl}/photos-crud/translate`;

  try {
    const result = await axios.post(
      url,
      {
        photos,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const deletePhoto = async (
  token: string,
  id: number,
): Promise<{
  status: string;
}> => {
  const url = `${parameters.api.baseUrl}/photos-crud/${id}`;

  try {
    const result = await axios.delete(
      url,

      { headers: { Authorization: `Bearer ${token}` } },
    );

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

// eslint-disable-next-line camelcase
export const upload = async (token: string, file: object, album_id?: number): Promise<Photo> => {
  const url = `${parameters.api.baseUrl}/photos-crud/upload`;

  const res = await axios.post(
    url,
    {
      file,
      // eslint-disable-next-line camelcase
      album_id,
    },
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } },
  );

  return res.data;
};

export const authors = async (token: string, fullList?: boolean): Promise<Author[]> => {
  let url = `${parameters.api.baseUrl}/photos-crud/authors`;

  if (fullList) {
    url += '?all=1';
  }

  return genericApi.listWithoutPagination<Author>(token, url);
};

export const download = async (token: string, ids: string): Promise<{ downloadUrl: string }> => {
  const url = `${parameters.api.baseUrl}/photos-crud/download${ids}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    // console.log('e', e);
    if (axios.isAxiosError(e)) {
      // console.log('e 2', e);
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};
