import React, { useState } from 'react';
import { Disclosure, Menu } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import DatePicker from 'react-datepicker';
import { diff } from 'deep-object-diff';

import FilterCompanies from '../../entities/filter/companies';
import { strings } from '../../localization/strings';
import { useDebounce } from '../../hooks/use-debounce';
import { SimpleObject } from '../../entities/simple-object';
import { fromJsDate } from '../../services/formatter/date';
import { pluralizeWithNumber } from '../../services/formatter/pluralize';

const filters = {
  activity: [
    { value: -1, label: 'Visi' },
    { value: 1, label: 'Aktyvus' },
    { value: 0, label: 'Neaktyvus' },
  ],
  is_active: [
    { value: 1, label: 'Aktyvus', checked: true },
    { value: 0, label: 'Neaktyvus', checked: true },
  ],
  service: [
    { value: 'subscription', label: 'Prenumerata' },
    { value: 'pressrelease', label: 'Pranešimai spaudai' },
  ],
};

export default function CompaniesFilter({
  onUpdate,
  initialState,
  filterState,
}: {
  onUpdate: (filters: FilterCompanies) => void;
  initialState: FilterCompanies;
  filterState: FilterCompanies;
}) {
  const [dateValue, setDateValue] = useState<SimpleObject<Date | null>>({
    valid_to_start: filterState.valid_to_start || null,
    valid_to_end: filterState.valid_to_end || null,
    pressrelease_creation_valid_to_start: filterState.pressrelease_creation_valid_to_start || null,
    pressrelease_creation_valid_to_end: filterState.pressrelease_creation_valid_to_end || null,
  });

  const activeFilters = Object.keys(diff(initialState, filterState)).length;

  const [inputValue, setInputValue] = useState<SimpleObject<string>>({
    search: filterState.search || '',
    // Add new inputs to the same state
  });

  const onDelayedTextChange = useDebounce((name: keyof FilterCompanies, value: string) => {
    onUpdate({ ...filterState, [name]: value });
  }, 500);

  const onTextChange = (name: keyof FilterCompanies, value: string) => {
    setInputValue({ ...inputValue, [name]: value });
    onDelayedTextChange(name, value);
  };

  const onChangeDate = (startFieldName: string, endFieldName: string, date: [Date | null, Date | null]) => {
    setDateValue({ ...dateValue, [startFieldName]: date[0], [endFieldName]: date[1] });

    if (date[0] && date[1]) {
      onUpdate({ ...filterState, [startFieldName]: fromJsDate(date[0]), [endFieldName]: fromJsDate(date[1]) });
    }

    if (!date[0] && !date[1]) {
      onUpdate({ ...filterState, [startFieldName]: null, [endFieldName]: null });
    }
  };

  const onRadioChange = (name: keyof FilterCompanies, value: number | string) => {
    onUpdate({ ...filterState, [name]: value });
  };

  const onCheckboxChange = (name: keyof FilterCompanies, value: number | string, checked: boolean) => {
    const selected = (filterState[name] as typeof value[]) || [];

    if (Array.isArray(selected)) {
      const index = selected.indexOf(value);

      if (checked) {
        if (index === -1) {
          selected.push(value);
        }
      } else {
        selected.splice(index, 1);
      }
    }

    onUpdate({ ...filterState, [name]: selected });
  };

  const clearFilter = () => {
    // Reset date values that are no in the initial state
    const updatedDateValue = { ...dateValue };

    for (const [key, value] of Object.entries(dateValue)) {
      if (value !== null && !initialState[key as keyof FilterCompanies]) {
        updatedDateValue[key] = null;
      }
    }
    setDateValue(updatedDateValue);

    // Reset input values that are no in the initial state
    const updatedInputValue = { ...inputValue };

    for (const [key, value] of Object.entries(inputValue)) {
      if (value !== '' && !initialState[key as keyof FilterCompanies]) {
        updatedInputValue[key] = '';
      }
    }
    setInputValue(updatedInputValue);

    onUpdate({ ...initialState });
  };

  return (
    <div className="bg-white">
      {/* Filters */}
      <Disclosure as="section" aria-labelledby="filter-heading" className="grid items-center border-b border-gray-200">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
            <div>
              <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                <FunnelIcon
                  className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                {activeFilters > 0
                  ? pluralizeWithNumber(activeFilters, strings.companies.filter.activeFilters)
                  : strings.companies.filter.select}
              </Disclosure.Button>
            </div>
            <div className="pl-6">
              {activeFilters > 0 && (
                <button type="button" onClick={clearFilter} className="text-gray-500">
                  {strings.filter.clearFilter}
                </button>
              )}
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-200 py-5">
          <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">{strings.companies.filter.loginState}</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.is_active.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                      <input
                        id={`is_active-${optionIdx}`}
                        name="is_active[]"
                        defaultValue={option.value}
                        type="checkbox"
                        onChange={(event) => onCheckboxChange('is_active', option.value, event.target.checked)}
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        defaultChecked={filterState.is_active.indexOf(option.value) !== -1}
                      />
                      <label htmlFor={`is_active-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600">
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">{strings.companies.filter.serviceState}</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.activity.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                      <input
                        id={`is_valid_subscription-${optionIdx}`}
                        name="is_valid_subscription"
                        defaultValue={option.value}
                        type="radio"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={() => onRadioChange('is_valid_subscription', option.value)}
                        defaultChecked={filterState.is_valid_subscription === option.value}
                      />
                      <label
                        htmlFor={`is_valid_subscription-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">{strings.companies.filter.service}</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.service.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                      <input
                        id={`service-${optionIdx}`}
                        name="service[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={(event) => onCheckboxChange('service', option.value, event.target.checked)}
                        defaultChecked={filterState.service && filterState.service.indexOf(option.value) !== -1}
                      />
                      <label htmlFor={`service-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600">
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">{strings.companies.filter.subscriptionExpirationDate}</legend>
                <div className="sm:mt-4">
                  <DatePicker
                    monthsShown={2}
                    showYearDropdown
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    selectsRange
                    startDate={dateValue.valid_to_start}
                    selected={dateValue.valid_to_start}
                    endDate={dateValue.valid_to_end}
                    onChange={(date) => onChangeDate('valid_to_start', 'valid_to_end', date)}
                  />
                </div>

                <legend className="block font-medium sm:pt-4">
                  {strings.companies.filter.pressReleaseExpirationDate}
                </legend>
                <div className="sm:mt-4">
                  <DatePicker
                    monthsShown={2}
                    showYearDropdown
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    selectsRange
                    startDate={dateValue.pressrelease_creation_valid_to_start}
                    selected={dateValue.pressrelease_creation_valid_to_start}
                    endDate={dateValue.pressrelease_creation_valid_to_end}
                    onChange={(date) =>
                      onChangeDate('pressrelease_creation_valid_to_start', 'pressrelease_creation_valid_to_end', date)
                    }
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </Disclosure.Panel>
        <div className="col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block">
              <div className="flex">
                <input
                  id="search"
                  name="search"
                  className="block bg-white border border-gray-300 rounded-md py-2 px-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                  placeholder={strings.common.searchLabel}
                  type="search"
                  onChange={(event) => onTextChange('search', event.target.value)}
                  value={inputValue.search}
                />
                {/* <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"> */}
                {/*  Sort */}
                {/*  <ChevronDownIcon */}
                {/*    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" */}
                {/*    aria-hidden="true" */}
                {/*  /> */}
                {/* </Menu.Button> */}
              </div>
            </Menu>
          </div>
        </div>
      </Disclosure>
    </div>
  );
}
