import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

interface ComponentProps {
  title: string;
  description?: string;
  buttonConfirm?: string;
  buttonCancel?: string;
  buttonConfirmAll?: string;
  open: boolean;
  danger?: boolean;
  cancelled: () => void;
  confirmed: () => void;
  confirmedAll?: () => void;
}

function CommonConfirmationModal({
  title,
  buttonConfirm,
  buttonCancel,
  buttonConfirmAll,
  description,
  danger,
  confirmed,
  cancelled,
  open,
  confirmedAll,
}: ComponentProps) {
  const onConfirm = () => {
    confirmed();
  };

  const onCancel = () => {
    cancelled();
  };

  const onConfirmAll = () => {
    confirmedAll && confirmedAll();
  };

  return (
    <Transition show={open}>
      <Dialog className="relative z-999" onClose={onCancel}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div
                    className={classNames(
                      'mx-auto flex h-12 w-12 items-center justify-center rounded-full',
                      danger ? 'bg-red-100' : 'bg-blue-100',
                    )}
                  >
                    <ExclamationTriangleIcon
                      className={classNames('h-6 w-6', danger ? 'text-red-600' : 'text-blue-600')}
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    {description && (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                      danger
                        ? 'bg-red-800 hover:bg-red-900 focus-visible:outline-red-600'
                        : 'bg-blue-800 hover:bg-blue-900 focus-visible:outline-indigo-600',
                    )}
                    onClick={() => onConfirm()}
                  >
                    {buttonConfirm}
                  </button>
                  {buttonConfirmAll && (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-red-800 hover:bg-red-900 focus-visible:outline-red-600"
                      onClick={() => onConfirmAll()}
                    >
                      {buttonConfirmAll}
                    </button>
                  )}
                  {!buttonConfirmAll && (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                      onClick={() => onCancel()}
                      data-autofocus
                    >
                      {buttonCancel}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

CommonConfirmationModal.defaultProps = {
  description: '',
  danger: false,
  buttonConfirm: 'Yes',
  buttonCancel: 'No',
  buttonConfirmAll: '',
  confirmedAll: () => null,
};

export default CommonConfirmationModal;
