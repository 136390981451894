import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import StyledInput from '../../../components/common/form/styled-input';
import CommonButtonDefault from '../../../components/common/buttons/default';
import * as albumsService from '../../../services/api/albums';
import { useAuth } from '../../../hooks/use-auth';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import { strings } from '../../../localization/strings';
import LabelWithError from '../../../components/common/form/label-with-error';
import { Album } from '../../../entities/album';

type Inputs = {
  title: string;
};

interface ComponentProps {
  onCloseForm: (data: boolean) => void;
  album?: Album;
}

export default function AlbumCreateForm({ onCloseForm, album }: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const { token } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: Album) => albumsService.update(token, data.id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['albums'],
      });
      onCloseForm(true);
    },
  });

  const createMutation = useMutation({
    mutationFn: (data: { title: string }) => albumsService.create(token, data),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { title: album?.title },
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      if (album) {
        await mutation.mutateAsync({ ...data, id: album.id });
      } else {
        const res = await createMutation.mutateAsync(data);

        setIsLoading(false);
        if (res) {
          navigate(`/albums/view/${res?.id}`);
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="py-2 text-xs">
          <CommonAnimatedSpinner sm />
        </div>
      )}
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <LabelWithError title={strings.album.form.label.title} error={errors.title?.message}>
            <StyledInput {...register('title', { required: true })} />
          </LabelWithError>
        </div>

        {error && <AlertPrimary type="danger" text={error} />}

        <div className="py-3 flex justify-end space-x-4">
          <CommonButtonDefault type="button" secondary onClick={() => onCloseForm(true)}>
            {strings.button.cancel}
          </CommonButtonDefault>
          <CommonButtonDefault primary type="submit">
            {strings.button.save}
          </CommonButtonDefault>
        </div>
      </form>
    </>
  );
}

AlbumCreateForm.defaultProps = {
  album: undefined,
};
