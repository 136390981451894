import React from 'react';
import { useParams } from 'react-router-dom';

import { strings } from '../../localization/strings';
import PhotosDropzone from '../../components/common/form/dropzone-photos';

export default function AlbumsUploadPageComponent() {
  const { id } = useParams();

  return (
    <>
      <header className="mb-3">
        <div className="flex space-x-3 justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">{strings.album.header.upload}</h1>
        </div>
      </header>
      <div className="max-w-7xl">
        <PhotosDropzone albumId={Number(id)} />
      </div>
    </>
  );
}
