import React from 'react';

import * as photosService from '../api/photos';

export const createIdsLink = (ids: number[]) => {
  let photosIds = '?ids[]=';

  for (let i = 0; i < ids.length; i + 1) {
    if (i === 0) {
      photosIds = `?ids[]=${ids[i]}`;
    } else {
      photosIds += `&ids[]=${ids[i]}`;
    }
  }

  return photosIds;
};

export const downloadPhoto = async (
  token: string,
  id: number,
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorCommon: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
  setIsDownloading(true);
  const photosIds = `?ids[]=${id}`;

  try {
    const res = await photosService.download(token, photosIds);

    if (res) {
      window.location.href = res.downloadUrl;
    }
  } catch (e) {
    setErrorCommon((e as Error)?.message);
  } finally {
    setIsDownloading(false);
  }
};
