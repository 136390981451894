import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ArrowLeftIcon } from '@heroicons/react/16/solid';

import { strings } from '../../../localization/strings';
import * as photosService from '../../../services/api/photos';
import { useAuth } from '../../../hooks/use-auth';
import { Photo } from '../../../entities/photo';
import { fillFromParams } from '../../../services/crud/filter';
import { Pagination } from '../../../entities/pagination';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { Author } from '../../../entities/author';
import EditPhotoForm from '../components/edit-form';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import { CompanyAdminStructure } from '../../../entities/company-admin-structure';
import { companyAdminStructure } from '../../../services/api/categories';

function PhotosEditPageComponent() {
  const [searchParams] = useSearchParams();

  const photosIds = searchParams.getAll('ids');

  const initialState = {
    ids: photosIds,
  };

  const types = {
    isArray: ['ids'],
  };
  const { token } = useAuth();

  const {
    data: photosToEdit,
    isError: isPhotosToEditError,
    error: photosToEditError,
    isLoading: isEditedPhotosLoading,
  } = useQuery<{ items: Photo[]; pagination: Pagination }, Error>({
    queryKey: ['photosToEdit', searchParams],
    queryFn: async () => {
      const params = fillFromParams(initialState, searchParams, types);

      const items = await photosService.list(token, { ...params, limit: 1000 });

      if (items && items.items.length > 0) {
        for (const item of items.items) {
          if (item.categories && item.categories.length === 0) {
            item.categories = [{ id: null, parent_id: null }];
          } else {
            for (const categoryRow of item.categories) {
              if (categoryRow && categoryRow.parent_id === null && categoryRow.id) {
                categoryRow.parent_id = categoryRow.id;
                categoryRow.id = null;
              }
            }
          }
        }
      }

      return items;
    },
    cacheTime: 0, // Disable cache time
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const { data: authors, error: authorsError } = useQuery<Author[], Error>({
    queryKey: ['photoAuthors'],
    queryFn: () => photosService.authors(token),
    refetchOnWindowFocus: false,
  });

  const { data: languages } = useQuery<CompanyAdminStructure>({
    queryKey: ['languages'],
    queryFn: () => companyAdminStructure(token),
    refetchOnWindowFocus: false,
  });

  return (
    <div className="max-w-7xl">
      <header className="mb-5">
        <div className="flex space-x-3 justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">{strings.photos.header.edit}</h1>
        </div>
      </header>
      {isPhotosToEditError && photosToEditError && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary type="danger" text={photosToEditError.message} />
        </div>
      )}
      {authorsError && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary type="danger" text={authorsError.message} />
        </div>
      )}
      {/* {languagesError && ( */}
      {/*  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5"> */}
      {/*    <AlertPrimary text={languagesError.message} /> */}
      {/*  </div> */}
      {/* )} */}
      {isEditedPhotosLoading && <CommonAnimatedLoader />}
      {photosToEdit && photosToEdit.items.length > 0 ? (
        authors &&
        languages && <EditPhotoForm items={photosToEdit.items} authors={authors} languages={languages.languages} />
      ) : (
        <>
          <AlertPrimary type="danger" text={strings.photos.edit.form.errors.noPhoto} />
          <Link className="mt-5 flex items-center space-x-1 text-gray-600" to="/photos/local">
            <ArrowLeftIcon width={15} height={15} /> <span>{strings.photos.backToList}</span>
          </Link>
        </>
      )}
    </div>
  );
}

PhotosEditPageComponent.defaultProps = {
  afterUpload: false,
};

export default PhotosEditPageComponent;
