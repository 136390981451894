import React from 'react';

import { strings } from '../../../localization/strings';
import PhotosDropzone from '../../../components/common/form/dropzone-photos';

function PhotosUploadPageComponent() {
  return (
    <>
      <header className="mb-5">
        <div className="flex space-x-3 justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">{strings.photos.header.upload}</h1>
        </div>
      </header>
      <PhotosDropzone />
    </>
  );
}

export default PhotosUploadPageComponent;
