import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { strings } from '../../localization/strings';
import { Pagination } from '../../entities/pagination';
import { SimpleObject } from '../../entities/simple-object';
import { fillFromParams } from '../../services/crud/filter';
import { Album } from '../../entities/album';
import * as albumsService from '../../services/api/albums';
import AlbumsFilter from './components/filter';
import AlbumComponent from './components/item';
import CommonButtonDefault from '../../components/common/buttons/default';
import ModalContent from '../../components/modals/content';
import { useModal } from '../../hooks/use-modal';
import { ModalKeys } from '../../context/modal';
import AlbumCreateForm from './components/album-create-form';
import FilterPhotos from '../../entities/filter/photo';
import PaginationComponent from '../../components/common/pagination';

function AlbumsPageComponent() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [error, setError] = useState<string>();

  const initialState: FilterPhotos = {
    page: 0,
    sort: '-created_at',
    is_published: [1, 0],
    language_id: 0,
  };

  const types = {
    isNumeric: ['page', 'is_published'],
    isDate: ['valid_to_start', 'valid_to_end'],
    isArray: ['is_published'],
  };

  const { token } = useAuth();
  const [params, setParams] = useState<FilterPhotos>(fillFromParams<FilterPhotos>(initialState, searchParams, types));

  const { setVisible } = useModal();
  const queryClient = useQueryClient();

  const {
    data: albums,
    error: albumsError,
    isLoading: isAlbumsLoading,
    isFetching: isAlbumsFetching,
  } = useQuery<{ items: Album[]; pagination: Pagination }, Error>({
    queryKey: ['albums', params],
    queryFn: () => albumsService.list(token, params),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });

  const { mutate: updateMutation, isLoading: isUpdating } = useMutation({
    mutationFn: async (data: Album) => {
      try {
        await albumsService.update(token, data.id, data);
      } catch (e) {
        setError((e as Error).message);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['albums'],
      });
    },
  });

  const deleteItem = ({ id, deleteAll }: { id: number; deleteAll?: number }) => {
    return albumsService.deleteAlbum(token, id, deleteAll);
  };

  const { mutate: deleteMutation, error: deleteError } = useMutation(deleteItem, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['albums', params] });
    },
  });

  const setFilterData = (filterData: FilterPhotos) => {
    const q: SimpleObject = {};

    // Cleanup undefined and null values. We don't want them in URLs
    for (const [key, value] of Object.entries(filterData)) {
      if (typeof value !== 'undefined' && value !== null) {
        q[key] = value;
      }
    }

    setParams({ ...filterData, page: 0 });
    setSearchParams({ ...q, page: 0 } as SimpleObject);
  };

  const updatePage = (currentPage: number) => {
    setParams({ ...params, page: currentPage });
    setSearchParams({ ...params, page: currentPage } as SimpleObject);
  };

  const onPublish = (item: Album) => {
    updateMutation({ ...item, is_published: 1 });
  };

  const onDelete = (id: number) => {
    deleteMutation({ id });
  };

  const onDeleteAll = (id: number) => {
    deleteMutation({ id, deleteAll: 1 });
  };

  return (
    <div>
      <header className="mb-3">
        <div className="flex space-x-3 justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">{strings.navigation.photoAlbums}</h1>
          <div>
            <CommonButtonDefault type="button" primary onClick={() => setVisible(ModalKeys.CreateItem, true)}>
              {strings.button.create}
            </CommonButtonDefault>
          </div>
        </div>
      </header>
      <main className="w-full">
        {albumsError && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
            <AlertPrimary type="danger" text={albumsError.message} />
          </div>
        )}
        {error && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
            <AlertPrimary type="danger" text={error} />
          </div>
        )}

        {deleteError ? (
          <AlertPrimary text={deleteError instanceof Error ? deleteError.message : String(deleteError)} />
        ) : null}
        <AlbumsFilter onUpdate={setFilterData} initialState={initialState} filterState={params} />
        {isAlbumsLoading || isAlbumsFetching || isUpdating ? (
          <CommonAnimatedLoader />
        ) : (
          <div className="py-5 lg:py-7">
            <h2 className="sr-only">Photo albums</h2>

            {albums && albums.items.length > 0 ? (
              <>
                <div className="grid grid-cols-1 gap-y-3 sm:grid-cols-3 sm:gap-x-3 sm:gap-y-3 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-8 mb-4">
                  {albums.items.map((item) => (
                    <AlbumComponent
                      key={item.id}
                      item={item}
                      onPublish={(i) => onPublish(i)}
                      onDelete={(id) => onDelete(id)}
                      onDeleteAll={(id) => onDeleteAll(id)}
                    />
                  ))}
                </div>

                <PaginationComponent
                  hasBg={false}
                  pagination={albums.pagination}
                  onChangePage={(page) => updatePage(page)}
                />
              </>
            ) : (
              <div>{strings.photos.list.empty}</div>
            )}

            <ModalContent
              type={ModalKeys.CreateItem}
              onCloseClick={() => setVisible(ModalKeys.CreateItem, false)}
              title={strings.album.modal.title}
            >
              <div className="min-w-96 pt-5">
                <AlbumCreateForm onCloseForm={() => setVisible(ModalKeys.CreateItem, false)} />
              </div>
            </ModalContent>
          </div>
        )}
      </main>
    </div>
  );
}

export default AlbumsPageComponent;
